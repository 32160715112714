@import url("https://fonts.googleapis.com/css2?family=Figtree:ital,wght@0,300..900;1,300..900&family=Inter:wght@100..900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Lexend:wght@100..900&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

input[type="text"],
input[type="date"],
input[type="email"],
input[type="number"],
select,
textarea {
  border-color: #d1d5db;
}

button:focus {
  z-index: 0 !important;
  outline: none !important;
  box-shadow: none !important;
}

body {
  &.menu-opened {
    &::after {
      content: "";
      display: block;
      height: 100vh;
      width: 100%;
      z-index: 1;
      background: rgba(75, 85, 99, 0.75);
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
    }
  }

  .dashboard-v2 {
    display: flex;

    aside {
      overflow-y: auto;
      height: 100lvb;
      z-index: 2;
    }

    main {
      height: 100lvb;
      overflow-y: auto;
      z-index: 1;
    }

    .users-page {
      table {
        tbody {
          tr {
            td {
              &:last-child {
                a {
                  visibility: hidden;
                }
              }
            }

            &:hover {
              td {
                &:last-child {
                  a {
                    visibility: visible;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

@media (min-width: 768px) {
  .container {
    max-width: 100%;
  }
}

@media (min-width: 640px) {
  .container {
    max-width: 100%;
  }
}

// flowBitePasswordInput
.flowBitePasswordInput input {
  border: none;
  padding: 0;
  border-radius: 0;
  background-color: transparent;

  &:focus {
    box-shadow: none;
    outline: none;
  }
}

// Input switch
.switch {
  position: relative;
  display: inline-block;
  width: 44px;
  height: 24px;
  outline: none;

  input {
    position: absolute;
    top: -99999px;
    left: -99999px;

    &:checked + .slider {
      background-color: #0ea5e9;
    }

    &:focus + .slider {
      box-shadow: 0 0 1px #0ea5e9;
    }

    &:checked + .slider:before {
      -webkit-transform: translateX(20px);
      -ms-transform: translateX(20px);
      transform: translateX(20px);
    }
  }

  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: 0.4s;
    transition: 0.4s;
    border-radius: 34px;

    &:before {
      position: absolute;
      content: "";
      height: 20px;
      width: 20px;
      left: 2px;
      bottom: 2px;
      background-color: white;
      -webkit-transition: 0.4s;
      transition: 0.4s;
      border-radius: 50%;
    }
  }
}

// Jsoneditor
.jsoneditor-menu a.jsoneditor-poweredBy {
  display: none;
}

.jsoneditor {
  border: none !important;
  z-index: 0;
}

.jsoneditor-outer {
  resize: vertical;
  overflow: auto;
}

.jsoneditor-menu {
  padding: 10px !important;
  border-radius: 20px 20px 0 0;
  height: 50px !important;
  background-color: #f3f4f6 !important;
  border-bottom: none !important;
  display: flex;
  justify-content: end;
}

.jsoneditor-menu .jsoneditor-repair {
  display: none;
}

.jsoneditor-menu button {
  color: #9ca3af !important;
}

.jsoneditor-menu > button,
.jsoneditor-menu > .jsoneditor-modes > button {
  background-image: url("../public/images/jsoneditor-icons.svg") !important;
}

.jsoneditor-statusbar {
  line-height: 26px !important;
  height: 40px !important;
  color: #808080 !important;
  background-color: transparent !important;
  border-top: 1px solid #ebebeb !important;
  -webkit-box-sizing: border-box !important;
  box-sizing: border-box !important;
  font-size: 14px !important;
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-align: center !important;
  -ms-flex-align: center !important;
  align-items: center !important;
  padding: 0 10px !important;
}

.lmcUserView {
  input[type="date"] {
    &::-webkit-calendar-picker-indicator {
      filter: invert(1) brightness(2);
    }
  }
}

// ================= Toastify
/** Used to define container behavior: width, position: fixed etc... **/
.Toastify__toast-container {
  align-items: flex-start;
}

/** Used to define the position of the ToastContainer **/
.Toastify__toast-container--top-left {
}
.Toastify__toast-container--top-center {
}
.Toastify__toast-container--top-right {
}
.Toastify__toast-container--bottom-left {
}
.Toastify__toast-container--bottom-center {
}
.Toastify__toast-container--bottom-right {
}

/** Classes for the displayed toast **/
.Toastify__toast {
}
.Toastify__toast--rtl {
}
.Toastify__toast-body {
  align-items: flex-start;

  & > div {
    color: #111827;

    /* text-sm/leading-5/font-medium */
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    //line-height: 20px; /* 142.857% */

    p {
      margin-top: 8px;
      color: #6b7280;

      /* text-sm/leading-5/font-normal */
      font-family: Inter;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px; /* 142.857% */
    }
  }
}

/** Used to position the icon **/
.Toastify__toast-icon {
  width: 25px;
  position: relative;
  top: -15px;
  height: 30px;
}

/** handle the notification color and the text color based on the theme **/
.Toastify__toast-theme--dark {
}
.Toastify__toast-theme--light {
  padding: 16px;
  align-items: flex-start;
  border-radius: 8px;
  background: var(--white, #fff);
  box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.1),
    0px 4px 6px -2px rgba(0, 0, 0, 0.05);
}
.Toastify__toast-theme--colored.Toastify__toast--default {
}
.Toastify__toast-theme--colored.Toastify__toast--info {
}
.Toastify__toast-theme--colored.Toastify__toast--success {
}
.Toastify__toast-theme--colored.Toastify__toast--warning {
}
.Toastify__toast-theme--colored.Toastify__toast--error {
}

.Toastify__progress-bar {
}
.Toastify__progress-bar--rtl {
}
.Toastify__progress-bar-theme--light {
}
.Toastify__progress-bar-theme--dark {
}
.Toastify__progress-bar--info {
}
.Toastify__progress-bar--success {
}
.Toastify__progress-bar--warning {
}
.Toastify__progress-bar--error {
}
/** colored notifications share the same progress bar color **/
.Toastify__progress-bar-theme--colored.Toastify__progress-bar--info,
.Toastify__progress-bar-theme--colored.Toastify__progress-bar--success,
.Toastify__progress-bar-theme--colored.Toastify__progress-bar--warning,
.Toastify__progress-bar-theme--colored.Toastify__progress-bar--error {
}

/** Classes for the close button. Better use your own closeButton **/
.Toastify__close-button {
  padding-top: 10px;
}
.Toastify__close-button--default {
}
.Toastify__close-button > svg {
}
.Toastify__close-button:hover,
.Toastify__close-button:focus {
}

.registerViaLink,
.loginViaLink {
  input,
  select {
    background-color: #fff;
  }
}

// Fix safari
#new_user_birth_date {
  max-height: 42px;
}

.myModal div:first-of-type {
  max-height: 100%;
}

@media (max-width: 768px) {
  .myModal div:first-of-type {
    max-height: 150%;
    width: 100%;
  }
}

.customToggle ::after {
  background-color: white;
  border-radius: 25px;
}

.customToggle > div {
  width: 2.75rem !important;
}

.direction-rtl {
  direction: rtl !important;
}
